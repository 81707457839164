exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about_us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-concept-tsx": () => import("./../../../src/pages/concept.tsx" /* webpackChunkName: "component---src-pages-concept-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investor-and-finance-tsx": () => import("./../../../src/pages/investor_and_finance.tsx" /* webpackChunkName: "component---src-pages-investor-and-finance-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-news-template-tsx": () => import("./../../../src/pages/news/template.tsx" /* webpackChunkName: "component---src-pages-news-template-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

